import styled from "styled-components";
import React from "react";
import { mobileSize } from "../../../util/variables";
import { Link } from "gatsby";
import { TopBanner } from "../../ui/TopBanner";
import { HeroSection } from "./landing/variation/HeroSection";
import TestimonialSection from "./landing/variation/TestimonialSection";
import { AppPitchSection } from "./landing/variation/AppPitchSection";
import CoursesSection from "./landing/variation/CoursesSection";
import { LP_VARIANT_MAPPING } from "../../../util/variantMappings";
import SpecialOfferModal from "../../Modal/SpecialOfferModal";
import { HowItWorks } from "../../gift/HowItWorks";

export const VariantComponent: React.FC<{
  variant: string | boolean | null;
  showModal?: boolean;
  buttonText?: string;
  ctaURL?: string;
  showBanner?: boolean;
}> = ({ variant, showModal = true, buttonText, ctaURL, showBanner = true }) => {
  const {
    mainTitle,
    subTitle,
    testimonialHeader,
    testimonials,
    valuePropsHeader,
    valueProps,
    heroImgDesktop,
    heroImgMobile,
    courseHeader,
  } = LP_VARIANT_MAPPING[variant as keyof typeof LP_VARIANT_MAPPING];

  return (
    <>
      {showBanner && (
        <TopBanner fixed={false}>
          <BannerText>
            Try Imprint free for 7 days and get
            <MobileBr />
            <Link to="/onboarding/questions">
              <b>20% OFF</b>
            </Link>{" "}
            Get Started
          </BannerText>
        </TopBanner>
      )}
      <HeroSection
        mainTitle={mainTitle}
        subTitle={subTitle}
        img={heroImgDesktop}
        mobileImg={heroImgMobile}
        buttonText={buttonText}
        ctaURL={ctaURL}
      />
      <TestimonialSection
        header={testimonialHeader}
        testimonials={testimonials}
        buttonText={buttonText}
        ctaURL={ctaURL}
      />
      {variant === "gifting" ? (
        <HowItWorks backgroundColor="#FEFEFE" />
      ) : (
        <AppPitchSection header={valuePropsHeader as string} valueProps={valueProps as any} />
      )}
      <CoursesSection coursesHeader={courseHeader} buttonText={buttonText} ctaURL={ctaURL} />
      {showModal && <SpecialOfferModal variant />}
    </>
  );
};

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;
