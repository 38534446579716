import styled from "styled-components";
import React, { useEffect } from "react";
import { BottomNavSegment } from "../../components/PageSegments/BottomNav";
import { BasicLayout } from "../../layouts/BasicLayout";
import { VariantComponent } from "../../components/PageSegments/special-offer/variantComponent";
import { fbq } from "../../services/pixel";
import { gift_page_view } from "../../services/mixpanel/gift-flow-events";

export default () => {
  useEffect(() => {
    gift_page_view("GiftStart");
    fbq("trackCustom", "landing_page_view");
  }, []);

  return (
    <BasicLayout>
      <Main>
        <VariantComponent
          variant="gifting"
          showModal={false}
          buttonText="Gift Imprint"
          ctaURL="/gift/start/"
          showBanner={false}
        />
      </Main>
      <BottomNavSegment />
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
