import React, { useLayoutEffect, useState } from "react";
import Modal from "../ui/Modal";
import styled from "styled-components";
import Close from "../../content/img/icon/close-icon.svg";
import { mobileSize } from "../../util/variables";
import { AppButton, AppText, FlexDiv } from "../ui";
import theme from "../../util/theme";
// import { graphql, useStaticQuery } from "gatsby";
import {
  web_acq_landing_page_modal_click,
  web_acq_landing_page_modal_closed,
  web_acq_landing_page_modal_view,
} from "../../services/mixpanel/acquisition-events";

import DesktopSrcC from "../../content/img/homepage/offer-modal-desktop_c.webp";
import MobileSrcC from "../../content/img/homepage/offer-modal-mobile_c.webp";
import DesktopSrcVariant from "../../content/img/homepage/variant-offer-modal-desktop.png";
import MobileSrcVariant from "../../content/img/homepage/variant-offer-modal-mobile.png";
import { AppLink } from "../ui/AppLink";

const imageDataC = {
  desktop: DesktopSrcC,
  mobile: MobileSrcC,
  variant: DesktopSrcVariant,
  mobileVariant: MobileSrcVariant,
  alt: "Tapping on a phone screen with many books flying out of the screen",
};

const SpecialOfferModal = ({
  customGroup,
  specialDiscount = false,
  variant,
  offerParam,
}: {
  customGroup?: string;
  specialDiscount?: boolean;
  variant?: boolean;
  offerParam?: string | null;
}) => {
  // const imageData = useStaticQuery(graphql`
  //   query SpecialOfferModalQuery {
  //     mobile: file(relativePath: { eq: "homepage/special-offer-graphic-mobile.png" }) {
  //       childImageSharp {
  //         fixed(quality: 100, width: 266, height: 203) {
  //           ...GatsbyImageSharpFixed_noBase64
  //         }
  //       }
  //     }
  //     desktop: file(relativePath: { eq: "homepage/special-offer-graphic-desktop.png" }) {
  //       childImageSharp {
  //         fixed(quality: 100, width: 548, height: 437) {
  //           ...GatsbyImageSharpFixed_noBase64
  //         }
  //       }
  //     }
  //   }
  // `);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    web_acq_landing_page_modal_closed();
    setModalOpen(false);
  };

  useLayoutEffect(() => {
    setTimeout(openModal, 500);
    web_acq_landing_page_modal_view();
  }, []);

  return (
    <div>
      {isModalOpen && (
        <Modal close={closeModal}>
          <CloseButton type="button" onClick={closeModal}>
            <img src={Close} alt="x" height={24} width={24} />
          </CloseButton>
          <ContentGrid>
            <MobileWrapper>
              <ImageWrapper>
                <img
                  src={variant ? imageDataC.mobileVariant : imageDataC.mobile}
                  alt={variant ? imageDataC.mobileVariant : imageDataC.alt}
                />
              </ImageWrapper>
            </MobileWrapper>

            <TextWrapper
              direction="column"
              align="center"
              justify="center"
              style={{ padding: "12px 24px 24px 24px" }}
            >
              {variant ? null : <LimitedTimeTitle>- LIMITED TIME -</LimitedTimeTitle>}
              {variant ? <Title>LIMITED TIME OFFER</Title> : <Title>SPECIAL WELCOME OFFER</Title>}
              <AppText
                noMargin
                fontSize={56}
                mobileFontSize={40}
                fontWeight={variant ? 500 : 800}
                style={{ marginBottom: variant ? 16 : 0 }}
              >
                {variant ? "Welcome to Imprint" : specialDiscount ? "30% OFF" : "20% OFF"}
              </AppText>
              <AppText
                fontSize={variant ? 20 : 24}
                fontWeight={variant ? 400 : "normal"}
                mobileFontSize={18}
                color={variant ? theme.SLATE_COLOR_3 : theme.BLACK_COLOR}
              >
                for {customGroup ?? "new Imprint learners"}
                {/* {variant
                  ? "You’ve unlocked a special Black Friday deal: 40% off an Imprint subscription."
                  : `for ${customGroup ?? "new Imprint learners"}`} */}
              </AppText>
              <LinkWrapper>
                {variant ? (
                  <AppButton
                    width="100%"
                    style={{ minWidth: 295 }}
                    onClick={() => {
                      web_acq_landing_page_modal_click();
                      setModalOpen(false);
                    }}
                  >
                    Let's Go!
                  </AppButton>
                ) : (
                  <AppLink
                    to={
                      specialDiscount
                        ? "/onboarding/questions/?x_dsqk=true"
                        : `/onboarding/questions${offerParam ? `?offer=${offerParam}` : ""}`
                    }
                    width="100%"
                    style={{ minWidth: 295 }}
                    onClick={() => web_acq_landing_page_modal_click()}
                  >
                    Get Started
                  </AppLink>
                )}
              </LinkWrapper>
            </TextWrapper>
            <ImageWrapper>
              <DesktopWrapper>
                <img
                  src={variant ? imageDataC.variant : imageDataC.desktop}
                  alt={variant ? imageDataC.variant : imageDataC.alt}
                />
              </DesktopWrapper>
              {/* {variant === "A" && (
                <MobileWrapper style={{ marginBottom: 24 }}>
                  <img
                    src={imageDataC.mobile}
                    alt="magnifying glass looking at a butterfly on a sunflower"
                  />
                </MobileWrapper>
              )} */}
            </ImageWrapper>
          </ContentGrid>
        </Modal>
      )}
    </div>
  );
};

// interface VariantI {
//   variant: string;
// }

const CloseButton = styled.button`
  position: absolute;
  left: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: none;
  width: max-content;

  @media ${mobileSize} {
    margin-left: auto;
    right: 16px;
    border-radius: 999px;
    height: 24px;
    width: 24px;
    background-color: ${theme.WHITE_COLOR};
    img {
      filter: brightness(0);
    }
  }
`;

const LimitedTimeTitle = styled(AppText)`
  color: ${theme.SLATE_COLOR_3};
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 4.5fr 5.5fr;
  text-align: center;
  width: 100%;
  overflow: hidden;

  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    /* padding: 16px; */
  }
`;

const Title = styled.h3`
  padding: 8px 16px;
  margin-top: 12px;
  margin-bottom: 24px;
  border-radius: 4px;
  background-color: #fff67a;
  color: ${theme.BLACK_COLOR};
  text-transform: uppercase;
  font-size: 24px;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 500;

  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DesktopWrapper = styled.div`
  max-height: 437px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  max-width: 350px;
  @media ${mobileSize} {
    display: block;
  }
  img {
  }
`;

const TextWrapper = styled(FlexDiv)`
  @media ${mobileSize} {
    max-width: 350px;
  }
`;

export default SpecialOfferModal;
