import styled, { css } from "styled-components";
import { mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { Link } from "gatsby";

interface Props {
  margin?: string;
  background?: string;
  color?: string;
  width?: number | string;
  dark?: boolean;
  weight?: "300" | "400" | "500" | "600" | "700" | "800" | "900";
  fontSize?: number;
}

export const AppLink = styled(Link)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: ${({ width }) => (width ? (typeof width === "string" ? width : `${width}px`) : "343px")};
  height: 52px;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  padding: 1rem;
  border-radius: 32px;
  box-sizing: border-box;
  outline-color: ${theme.FOCUS_OUTLINE_COLOR};

  background: ${({ background, dark }) =>
    background ? background : dark ? theme.BLACK_COLOR : theme.PRIMARY_COLOR};
  color: ${({ color }) => color ?? "#fefefe"};
  font-family: ${theme.SECONDARY_FONT};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : "17px"};
  font-weight: ${({ weight }) => weight ?? "400"};

  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  @media ${mobileSize} {
    width: 100%;
  }
`;
